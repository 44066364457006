import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss'],
})
export class AdduserComponent implements OnInit {
  adduserForm: FormGroup;
  isInternal: boolean = false;
  isDisplay: boolean = false;
  isDisplayAll: boolean = false;
  userTypes: any;
  roles: any;
  filterroles: any;
  emailErrorMessage: any;
  firstNameError: any;
  guidError: any;
  lastNameError: any;
  clientsList: any = [];
  selectedroles: any = undefined;

  constructor(
    private formbuilder: FormBuilder,
    private globalService: GlobalService,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject
  ) {
    this.adduserForm = this.formbuilder.group({
      userType: [''],
      guid: [''],
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.email]],
      role: [null],
      clients: [null],
    });
  }

  ngOnInit(): void {
    this.fetchRefData();

    this.emailErrorMessage = 'Email is Required';
    this.firstNameError = 'First name is required';
    this.lastNameError = 'Last name is required';
    this.guidError = 'GUID is required';
  }
  public selectionChange(value: any): void {
    this.selectedroles = value;
    if (value.text == 'Super Admin') {
      this.isDisplayAll = true;
      this.isDisplay = false;
    } else {
      this.isDisplay = true;
      this.isDisplayAll = false;
    }
  }
  public selectionChangeType(value: any): void {
    this.selectedroles = undefined;
    if (value.value == 1)
      this.adduserForm.controls['guid'].setValidators(Validators.required);
    else this.adduserForm.controls['guid'].setErrors(null);

    this.filterroles = [];
    this.adduserForm.controls['role'].setValue('');
    if (value.text === 'External') {
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].text != 'Super Admin') {
          this.filterroles.push(this.roles[i]);
        }
      }
    } else {
      for (let i = 0; i < this.roles.length; i++) {
        this.filterroles.push(this.roles[i]);
      }
    }
  }
  fetchRefData() {
    this.globalService
      .fetchRefData()
      .then((res) => {
        let rolesDB = res.ApplicationRoleModel;
        let userTypesDB = res.UserTypeModel;
        let clientsListDB = res.ClientDetailsModel;
        this.userTypes = userTypesDB.map((obj: any) => {
          let userTypetemp = {
            text: '',
            value: '',
          };
          userTypetemp['text'] = obj.UserTypeName;
          userTypetemp['value'] = obj.UserTypeId;
          return userTypetemp;
        });
        this.clientsList = clientsListDB.map((obj: any) => {
          let clientstemp = {
            text: '',
            value: '',
          };
          clientstemp['text'] = obj.ClientEntityName;
          clientstemp['value'] = obj.ClientId;
          return clientstemp;
        });
        this.roles = rolesDB.map((obj: any) => {
          let rolestemp = {
            text: '',
            value: '',
          };
          rolestemp['text'] = obj.Role;
          rolestemp['value'] = obj.RoleId;
          return rolestemp;
        });

        this.filterroles = rolesDB.map((obj: any) => {
          let rolestemp = {
            text: '',
            value: '',
          };
          rolestemp['text'] = obj.Role;
          rolestemp['value'] = obj.RoleId;
          return rolestemp;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  saveUser() {
    var formObject = this.adduserForm.getRawValue();

    const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;

    var firstError = specialChars.split('').some((specialChar) => {
      if (formObject.firstName.includes(specialChar)) {
        return true;
      } else return false;
    });
    if (firstError) {
      this.firstNameError = 'Please enter name without special characters';
      this.adduserForm.controls['firstName'].setErrors({ incorrect: true });
      return;
    }
    var lastError = specialChars.split('').some((specialChar) => {
      if (formObject.lastName.includes(specialChar)) {
        return true;
      } else return false;
    });
    var guidError = specialChars.split('').some((specialChar) => {
      if (formObject.guid.includes(specialChar) && formObject.guid != '') {
        return true;
      } else return false;
    });
    if (guidError) {
      this.guidError = 'Please enter GUID without special characters';
      this.adduserForm.controls['guid'].setErrors({ incorrect: true });
      return;
    }
    if (lastError) {
      this.lastNameError = 'Please enter name without special characters';
      this.adduserForm.controls['lastName'].setErrors({ incorrect: true });
      return;
    }

    if (
      formObject.userType.value == 1 &&
      formObject.email.substring(formObject.email.lastIndexOf('@') + 1) !=
        'pwc.com'
    ) {
      this.emailErrorMessage = 'Please enter valid PWC email';
      this.adduserForm.controls['email'].setErrors({ incorrect: true });
      return;
    } else if (
      formObject.userType.value == 2 &&
      formObject.email.substring(formObject.email.lastIndexOf('@') + 1) ==
        'pwc.com'
    ) {
      this.emailErrorMessage = 'Please enter non PWC email';
      this.adduserForm.controls['email'].setErrors({ incorrect: true });
      return;
    }

    let clientVal;
    if (formObject.clients == null) {
      clientVal = '';
    } else {
      clientVal = formObject.clients.toString();
    }
    if (formObject.role.value == 1) {
      clientVal = '';
    }
    var createdOn = new Date();
    var dd = createdOn.toISOString();
    let userObject = {
      UserGUID: formObject.guid,
      FirstName: formObject.firstName,
      LastName: formObject.lastName,
      EmailID: formObject.email,
      RoleID: formObject.role.value,
      UserTypeID: formObject.userType.value,
      ClientId: clientVal,
      ClientName: clientVal,
      CreatedBy: this.myGlobalObject.loggedinUserDetail.mail,
      CreatedOn: dd,
      ModifiedBy: '',
      ModifiedOn: dd,
      IsActive: true,
      IsMasterUser: false,
    };

    this.globalService
      .saveUsers(userObject)
      .then((res) => {
        if (res) {
          if (res == 'User details added successfully') {
            this.toastr.successToastr(this.capitalize(res), 'Success');
            this.globalService.navigateTo('/users');
          } else if (res == 'User details already exists') {
            this.toastr.errorToastr(this.capitalize(res), 'Failed');
          } else {
            this.toastr.errorToastr(this.capitalize(res), 'Failed');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  cancelClick() {
    this.globalService.navigateTo('/users');
  }
  public capitalize(text: any) {
    return text[0].toUpperCase() + text.substr(1).toLowerCase();
  }
}
